import React, { Component } from 'react';
import Script from 'react-load-script'

const codeInit = `<div id="gpbook-root"></div>

<input id="gpbook-field" type="hidden" value="Cardiology">

<input id="gpbook-interest" type="hidden" value="Interventional Cardiology">

<link href="https://wapha.gpbook.com.au/static/css/main.css" rel="stylesheet">
<script crossOrigin src="https://wapha.gpbook.com.au/static/js/main.js" type="text/javascript"></script>`


class App extends Component {

  handleChange = (e) =>  this.setState({ code: e.target.value })
  handleReload = e => {
    window.gpbookInit();
  }
  handleReset = e => {
    this.setState({ code: ``, reloading: true }, () => {
      this.setState({ code: codeInit, reloading: false })
    })
      
    setTimeout(() => {
      window.gpbookInit();
    }, 250)
  }
  render() {
    return (
      <div>
        <div className="healthpathways">
          <Header />
          <div className="main">
            <Sidebar>
              <hr/>
              <strong>Code Editor</strong>
              <p style={{margin:0}}>Please update the code below and hit the reload button to test your changes.</p>
              <textarea onChange={this.handleChange} value={this.state.code} />
              <button className="reload" onClick={this.handleReload}>Reload/Test</button>
              <button className="reset" onClick={this.handleReset} >Reset</button>
            </Sidebar>
            <Content>
              {this.state.reloading ? `` : <div dangerouslySetInnerHTML={{ __html: this.state.code }} ></div>}
              <Script url="https://wapha.gpbook.com.au/static/js/main.js?reload=true" />
           </Content>
          </div>
        </div>
      </div>
    );
  }

  state = {
    code: codeInit,
    load: false,
    reloading: false
  }
}


const Content = ({ children }) =>
<div className="content">
    <h2>
      Pathway Title
      {/* <span className="edit">edit</span> */}
    </h2>
  <p>
    About the health pathway being viewed.
  </p>
  <h3 className="h3">Red Flags</h3>
  <p >These are examples of red flags to watch out for:</p>
  <ul className="ul">
    <li className="p placeholder width-33"></li>
    <li className="p placeholder width-33"></li>
    <li className="p placeholder width-33"></li>
    <li className="p placeholder width-33"></li>
  </ul>
  <h4 className="h4">Information</h4>
  <p className="p placeholder width-66"></p>
  <p className="p placeholder width-50"></p>
    <p className="p placeholder width-33"></p>
    <hr/>
{children}
</div>

const Header = () =>
<div className="header">
<img src={require('./banner.png')} alt="Banner" width="100%" />
</div>

const Sidebar = ({ children}) => 
<div className="left-sidebar">
  <div className="search placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
  <div className="p placeholder"></div>
    <div className="p placeholder"></div>
    {children}
</div>

export default App;